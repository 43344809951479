/* eslint-disable no-undef */
// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"

import Appsignal from 'utils/appsignal';
import { installErrorHandler } from "@appsignal/stimulus"

const application = Application.start()
window.Stimlulus = application;
// Report errors to appsignal
installErrorHandler(Appsignal, application);

import { definitions as context } from "stimulus:../controllers";
application.load(context);
import { definitions as contextComponents } from "stimulus:../../components";
application.load(contextComponents);
import { definitions as viewControllers } from "stimulus:../../views";
application.load(viewControllers);
import { definitions as subscriberV2Components } from "stimulus:../../../engines/subscriber_v2/app/components/subscriber_v2";
application.load(subscriberV2Components);
import { definitions as subscriberV2Views } from "stimulus:../../../engines/subscriber_v2/app/views/subscriber_v2";
application.load(subscriberV2Views);
