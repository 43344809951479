import { Controller } from "stimulus";

export default class ShowController extends Controller {
  loadEpisode(event) {
    event.preventDefault();

    const audioPlayer = this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-audio-player]'),
      'media--audio-player'
    );
    const timestamp = parseInt(event.target.getAttribute('data-timestamp'));

    audioPlayer.loadEpisode({
      itemId: this.episodeIdValue,
      itemType: "Episode",
      audioUrl: this.audioUrlValue,
      timestamp: timestamp
    });
  }
}

ShowController.values = { episodeId: Number, audioUrl: String };