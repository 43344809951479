import { Controller } from 'stimulus';

export default class TrixDefaultMessage extends Controller {
  static targets = [ "input" ]

  connect() {
    this.inputTargets.forEach((element) => {
      let message = JSON.parse(element.dataset.inputMessage);
      let hidden_input = element.getElementsByTagName("input")[0]
      let trix_editor = element.getElementsByTagName("trix-editor")[0]

      trix_editor.innerHTML = message
      if ( message != "null" ) {
        hidden_input.setAttribute("value", message)
      }
    });
  }
}
