import { Controller } from 'stimulus'

export default class PasswordValidator extends Controller {
  static targets = ["password", "passwordConfirmation", "warning", "submit"]

  connect() {
    this.submitTarget.disabled = false;

    let self = this;
    this.passwordConfirmationTarget.addEventListener("input", () => {
      let matchingPassword = self.passwordConfirmationTarget.value === self.passwordTarget.value;
      let hasPassword = self.passwordTarget.value.length > 1;
      let hasPasswordConfirmation = self.passwordTarget.value.length > 1;

      if ( !matchingPassword && hasPassword && hasPasswordConfirmation ) {
        self.warningTarget.style.display = "block";
        self.warningTarget.innerHTML = "Passwords don't match"
        self.warningTarget.classList.add("callout")
        self.submitTarget.disabled = true;
      }
      else {
        self.warningTarget.style.display = "none";
        self.submitTarget.disabled = false;
      }
    });
  }
}
