import c0 from './dashboard/channels/episodes/form_controller';
import c1 from './dashboard/channels/episodes/upload_controller';
import c2 from './dashboard/channels/landing_page/testimonials_controller';
import c3 from './internal/helpscout/show_controller';
export const definitions = [
	{identifier: 'dashboard--channels--episodes--form', controllerConstructor: c0},
	{identifier: 'dashboard--channels--episodes--upload', controllerConstructor: c1},
	{identifier: 'dashboard--channels--landing-page--testimonials', controllerConstructor: c2},
	{identifier: 'internal--helpscout--show', controllerConstructor: c3},
];
