import { Controller } from 'stimulus';

export default class ImagePreview extends Controller {
  static targets = ["logo"]

  initialize() {
    const url = this.logoTarget.dataset.url
    const filename = this.logoTarget.dataset.filename

    if ( url === undefined || filename === undefined ) { return; }
    if ( url.length > 0 && filename.length > 0 ) {
      let style = this.logoTarget.querySelector(".wrapped-file-preview").style;

      style.backgroundImage = `url("${url}")`;
      style.backgroundPosition = "center";
      style.backgroundSize = "cover";

      this.logoTarget.querySelector(".wrapped-file-label").innerHTML = filename
    }
  }
}
