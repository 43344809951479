export class SupercastTimeUpdateEvent extends Event {
  constructor({ itemId, itemType, currentTime, duration, isPlaying, mediaType }) {
    super('supercast-timeupdate');
    this.detail = { itemId, itemType, currentTime, duration, isPlaying, mediaType };
  }
}

export class SupercastPlaybackEndedEvent extends Event {
  constructor({ itemId, itemType, mediaType }) {
    super('supercast-playback-ended');
    this.detail = { itemId, itemType, mediaType };
  }
}

export class SupercastLockOnPlayEvent extends Event {
  constructor({ itemId, itemType }) {
    super('supercast-lock-play');
    this.detail = { itemId, itemType };
  }
}