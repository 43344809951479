import { Controller } from 'stimulus';
export default class DropdownController extends Controller {
  connect() {
    this.hidden = true;
  }

  close() {
    this.hidden = true;
    this.hide();
  }

  toggle(e) {
    e.preventDefault();
    this.hidden = !this.hidden;
    if (this.hidden) {
      this.hide();
    } else {
      this.show();
    }
  }

  show() {
    this.menuTarget.classList.remove("hidden");
    window.requestAnimationFrame(() => {
      this.transition({
        element: this.menuTarget,
        from: ["scale-50", "opacity-0"],
        to: ["scale-100", "opacity-100"]
      });
    });
  }

  hide() {
    this.transition({
      element: this.menuTarget,
      from: ["scale-100", "opacity-100"],
      to: ["scale-50", "opacity-0"],
      hideAfter: 300
    });
  }

  transition({ element, from, to, hideAfter }) {
    element.classList.remove(...from);
    element.classList.add(...to);
    if (hideAfter) {
      setTimeout(() => {
        element.classList.add("hidden");
      }, hideAfter);
    }
  }
}

DropdownController.targets = ['menu'];
