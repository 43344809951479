import { Controller } from "stimulus";
import HelpScout from '@helpscout/javascript-sdk';

export default class ShowController extends Controller {
  async connect() {
    const context = await HelpScout.getApplicationContext();
    const urlParams = new URLSearchParams();
    urlParams.append('email', context.customer.emails[0].value);

    this.customerFrameTarget.src = "/internal/helpscout/customer?" + urlParams.toString();
  }
}

ShowController.targets = ["customerFrame"]