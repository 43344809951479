import { Controller } from 'stimulus';

export default class SearchToggleController extends Controller {
  connect() {
    this.addSubmitButton();
  }

  /**
   * Adds a hidden submit button to the form so that we can trigger JS
   * event handlers on the form (tried requestSubmit and dispatchEvent, but they didn't work)
   */
  addSubmitButton() {
    this.hiddenSubmit = document.createElement('input');
    this.hiddenSubmit.type = 'submit';
    this.hiddenSubmit.name = 'submit';
    this.hiddenSubmit.hidden = true;
    this.element.appendChild(this.hiddenSubmit);
  }

  search(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    if (form.requestSubmit) {
      form.requestSubmit();
    } else {
      this.hiddenSubmit.click();
    }
  }
}
