import { Controller } from "stimulus";
import Plyr from "@supercast/plyr";
import { SupercastTimeUpdateEvent, SupercastPlaybackEndedEvent } from "utils/events";
export default class extends Controller {
  static targets = [
    "controlsTemplate",
    "playback1",
    "playback1_5",
    "playback2"
  ]

  static values = {
    timestamp: Number,
    markers: Array,
    itemId: String,
    itemType: String,
    mediaMetadata: Object
  }

  connect() {
    this.initializePlayer();
    this.player.on("playing", this.onTimeUpdate.bind(this));
    this.player.on("pause", this.onTimeUpdate.bind(this));
    this.player.on("ratechange", this.onRateChange.bind(this));
    this.player.on("timeupdate", this.onTimeUpdate.bind(this));
    this.player.on("ended", this.playbackEnded.bind(this));

    this.setMediaSessionActionHandlers();
  }

  loadEpisode({ itemId, itemType, audioUrl, timestamp }) {
    this.itemIdValue = itemId;
    this.itemTypeValue = itemType;

    if (this.player.source != audioUrl) {
      const audio = this.element.querySelector("audio");
      audio.src = audioUrl;
    }

    if (timestamp) {
      this.timestampValue = timestamp;
    }

    this.setCurrentTime(this.element.querySelector("audio"));
    this.player.play();
  }

  // Duplicated in video player - consider keeping in sync
  onRateChange() {
    if (this.player.speed == 1) {
      this.playback1Target.classList.remove("!hidden")
      this.playback1_5Target.classList.add("!hidden")
      this.playback2Target.classList.add("!hidden")
    } else if (this.player.speed == 1.5) {
      this.playback1Target.classList.add("!hidden")
      this.playback1_5Target.classList.remove("!hidden")
      this.playback2Target.classList.add("!hidden")
    } else {
      this.playback1Target.classList.add("!hidden")
      this.playback1_5Target.classList.add("!hidden")
      this.playback2Target.classList.remove("!hidden")
    }
  }

  onTimeUpdate() {
    const event = new SupercastTimeUpdateEvent({
      itemId: this.itemIdValue,
      itemType: this.itemTypeValue,
      currentTime: this.player.currentTime,
      duration: this.player.duration,
      isPlaying: !this.player.paused,
      mediaType: "audio"
    });

    window.dispatchEvent(event);
    this.setMediaSessionPlaybackState();
  }

  playbackEnded() {
    const event = new SupercastPlaybackEndedEvent({
      itemId: this.itemIdValue,
      itemType: this.itemTypeValue,
      mediaType: "audio"
    });

    window.dispatchEvent(event);
  }

  play() {
    this.player.play();
  }

  pause() {
    this.player.pause();
  }

  // Duplicated in video player - consider keeping in sync
  togglePlaybackRate() {
    if (this.player.speed == 1) {
      this.player.speed = 1.5;
    } else if (this.player.speed == 1.5) {
      this.player.speed = 2;
    } else {
      this.player.speed = 1;
    }
  }

  initializePlayer() {
    const audio = this.element.querySelector("audio");

    this.setCurrentTime(audio);

    this.player = new Plyr(audio, {
      controls: this.controlsTemplateTarget.innerHTML,
      markers: this.markersValue,
      seekTime: 15,
      volume: 1
    });
  }

  async setCurrentTime(audio) {
    let listenData;
    if (this.hasTimestampValue && this.timestampValue > 0) {
      audio.currentTime = this.timestampValue;
    } else if (window.playbackDb) {
      const listenData = await window.playbackDb.getPlaybackData(this.itemTypeValue, this.itemIdValue);
      if (listenData) {
        audio.currentTime = listenData.currentTime;
      }
    }
  }

  setMediaSessionActionHandlers() {
    if (!("mediaSession" in navigator)) {
      return;
    }

    navigator.mediaSession.setActionHandler("play", () => {
      this.play();
    });

    navigator.mediaSession.setActionHandler("pause", () => {
      this.pause();
    });

    navigator.mediaSession.setActionHandler("seekbackward", () => {
      this.player.currentTime -= 15;
    });

    navigator.mediaSession.setActionHandler("seekforward", () => {
      this.player.currentTime += 15;
    });
  }

  setMediaSessionPlaybackState() {
    if (!("mediaSession" in navigator)) {
      return;
    }
    if (this.player.duration > this.player.currentTime) {
      /* We can sometimes get this when we're seeking - ignore it and wait for the next update */
      return;
    }

    navigator.mediaSession.setPositionState({
      duration: this.player.duration,
      playbackRate: this.player.speed,
      position: this.player.currentTime
    });

    navigator.mediaSession.playbackState = this.player.paused ? "paused" : "playing";
  }
}
