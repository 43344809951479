import { Controller } from "stimulus"
import { default as TomSelect } from "tom-select"

export default class TypeaheadComponent extends Controller {
  async connect() {
    this.addCss();
    this.initTomSelect()
  }

  addCss() {
    if (document.getElementById("tom-select-css")) {
      return;
    }

    const css = document.createElement("link");
    css.rel = "stylesheet";
    css.href = "https://cdn.jsdelivr.net/npm/tom-select@2.2.2/dist/css/tom-select.min.css";
    css.id = "tom-select-css";
    document.head.appendChild(css);
  }

  initTomSelect() {
    let config = {
      highlight: false,
    }

    this.tom = new TomSelect(this.element, config)
  }
}

