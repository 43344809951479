import { Controller } from 'stimulus';

export default class PlayerPreference extends Controller {
  static targets = ['player', 'channels']

  connect() {
    this.mode = this.element.dataset.playerPreferenceMode || "hide";

    this.toggleNext();
    this.toggleDevice();
  }

  toggleDevice() {
    const deviceStrings = this.readDeviceStrings();
    let showingOne = false;

    // We want everything selected if we're disabling. This can be simplified
    // when the unified connection beta is out.
    if (deviceStrings.length > 0 || this.mode == 'hide') {
      this.element.querySelectorAll('.player-preference-container').forEach(c => {
        if (this.playerAvailableFor(c.dataset.availableFor, deviceStrings)) {
          c.classList.remove(this.deselectedClass());
          showingOne = true;
        } else {
          c.classList.add(this.deselectedClass());
        }
      })
    }

    if (!showingOne) {
      this.element.querySelector('.player-heading').classList.add('hidden');
    } else {
      this.element.querySelector('.player-heading').classList.remove('hidden');
    }
  }

  deselectedClass() {
    return this.mode == 'disable' ? 'player-disabled' : 'hidden'
  }

  toggleOtherWarning() {
    const otherSelected = this.element.querySelector("#player-rss").checked;
    this.element.querySelector('.other-warning').style.display = otherSelected ? "block" : "none";
    this.toggleNext();
  }

  readDeviceStrings() {
    return Array.from(document.querySelectorAll('.device-preference:checked')).map(e => e.id);
  }

  playerAvailableFor(supportedDeviceString, deviceStrings) {
    return supportedDeviceString.split(",").some(device => deviceStrings.includes(device));
  }

  // Triggered when a player is clicked in a network context.
  playerClick(e) {
    this.element.querySelectorAll('.player-selected').forEach(el => el.classList.remove('player-selected'));
    e.currentTarget.classList.add('player-selected')
  }

  toggleNext() {
    const nextButton = this.element.querySelector("#next-button");

    if (nextButton !== null) {
      let hasOneSelected = false;
      this.playerTargets.forEach( el => {
        if (el.checked) { hasOneSelected = true }
      });

      hasOneSelected ?  nextButton.disabled = false : nextButton.disabled = true;
    }
  }
}
