/* eslint-disable no-undef */
import { Controller } from 'stimulus';
import { logEvent } from '../utils/analytics';

export default class PlayControls extends Controller {
  connect() {
    this.findOrSetupAudioElement();

    this.element.querySelector('.play-button').addEventListener('click', this.play.bind(this));
    this.element.querySelector('.pause-button').addEventListener('click', this.pause.bind(this));
    window.addEventListener('player:close', this.pause.bind(this));
  }

  findOrSetupAudioElement() {
    this.audioElement = this.element.querySelector('audio');
  }

  play() {
    if (window.Plyr) {
      Supercast.Player.setAudio.call(Supercast.Player, this.audioElement.id, true);
    } else {
      this.audioElement.play();
    }
    document.querySelectorAll(".pause-button").forEach(e => e.classList.add("hidden"));
    document.querySelectorAll(".play-button").forEach(e => e.classList.remove("hidden"));
    this.element.querySelector(".pause-button").classList.remove("hidden");
    this.element.querySelector(".play-button").classList.add("hidden");

    logEvent('Feed Browser - Episode played');
  }

  pause() {
    this.audioElement.pause();
    this.element.querySelector(".pause-button").classList.add("hidden");
    this.element.querySelector(".play-button").classList.remove("hidden");

    logEvent('Feed Browser - Episode paused');
  }
}
