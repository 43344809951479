import { Controller } from "stimulus";

export default class TestimonialsController extends Controller {
  addTestimonial(e) {
    e.preventDefault();
    const template = this.templateTarget.children[0];
    console.log(template);
    this.testimonialsTarget.appendChild(template);

    if (this.testimonialsTarget.children.length >= 3) {
      e.target.classList.add("hidden");
    }
  }
}

TestimonialsController.targets = ["template", "testimonials"];