import { Controller } from "stimulus";
import tomSelect from 'tom-select';

export default class FormController extends Controller {

    connect() {
        if (window.supercast) {
            window.supercast.trigger('pageload')
        }
    }

    playlistSelectTargetConnected(el) {
        this.playlistTom = new tomSelect(el, {
            create: (input) => {
                return {
                    value: "new:" + input,
                    text: input
                }
            }
        });
    }

    removeTimestamp(e) {
        e.preventDefault();
        e.target.closest("[data-timestamp]").remove();
    }

    emailContentTargetConnected(el) {
        el.addEventListener('trix-change', (e) => {
            this.emailContentAlertTarget.dataset.email = e.target.editor.getDocument().toString();
            this.maybeWarn();
        });

        this.maybeWarn();
    }

    maybeWarn() {
        if (this.emailContentAlertTarget.dataset.description !== this.emailContentAlertTarget.dataset.email) {
            this.emailContentAlertTarget.classList.remove('hidden');
        }
    }
}

FormController.targets = ["playlistSelect", "emailContent", "emailContentAlert"]
