import { Controller } from 'stimulus';
import { logEvent } from '../utils/analytics';

/**
 * Logs events to analytics whenever this element is clicked. Specify the name
 * of the event in data-event. All other data properties will be sent to analytics as well.
 */
export default class LikesController extends Controller {
  static targets = ["icon", "count", "link"]

  connect() {
    this.linkTarget.addEventListener('click', this.click.bind(this))

    this.isLiked = this.iconTarget.classList.contains('fas');
  }

  async click(e) {
    e.preventDefault();
    e.stopPropagation();
    const url = this.linkTarget.href;
    const response = await fetch(url, {
      method: this.isLiked ? 'delete' : 'post',
      credentials: 'include'
    })
    if (response.ok) {
      this.toggleLikeDisplay();
    }

    this.logToAnalytics();
  }

  logToAnalytics() {
    logEvent("Community - Like toggle", {
      isLike: this.isLiked,
      href: this.linkTarget.href,
      likeCount: this.countTarget.textContent,
      source: 'browser',
    });
  }

  toggleLikeDisplay() {
    if (this.isLiked) {
      this.iconTarget.classList.remove('fas');
      this.iconTarget.classList.add('far');
      this.countTarget.textContent = parseInt(this.countTarget.textContent) - 1;
      this.isLiked = false;
    } else {
      this.iconTarget.classList.remove('far');
      this.iconTarget.classList.add('fas');
      this.countTarget.textContent = parseInt(this.countTarget.textContent) + 1;
      this.isLiked = true;
    }
  }
}
