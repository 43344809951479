import { Controller } from 'stimulus';

export default class PlanUnwrap extends Controller {
  static targets = ["content", "description","showButton"]

  initialize () {
    if (this.descriptionTarget.offsetHeight > 600) {
      this.showButtonTarget.classList.remove("hidden")
      this.descriptionTarget.classList.add("plan-wrap")
    }
  }

  unwrap () {
    this.descriptionTarget.classList.remove("plan-wrap")
    event.target.classList.add("hidden")
  }
}
