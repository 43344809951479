import c0 from './ama_publish_controller';
import c1 from './amount_toggle_controller';
import c2 from './auto_save_controller';
import c3 from './autolink_controller';
import c4 from './beacon_link_controller';
import c5 from './click_tracker_controller';
import c6 from './confirmation_controller';
import c7 from './copy_to_clipboard_controller';
import c8 from './email_validation_controller';
import c9 from './expandable_content_controller';
import c10 from './extra_support_controller';
import c11 from './feed_item_switcher_controller';
import c12 from './form_controller';
import c13 from './global_promo_controller';
import c14 from './image_preview_controller';
import c15 from './likes_controller';
import c16 from './login_code_controller';
import c17 from './login_controller';
import c18 from './new_tab_link_controller';
import c19 from './password_validator_controller';
import c20 from './plan_toggle_controller';
import c21 from './plan_unwrap_controller';
import c22 from './play_controls_controller';
import c23 from './player_preference_controller';
import c24 from './preview_controller';
import c25 from './preview_target_controller';
import c26 from './range_selector_controller';
import c27 from './reorderable_list_controller';
import c28 from './search_filter_controller';
import c29 from './search_toggle_controller';
import c30 from './share_link_controller';
import c31 from './status_page_controller';
import c32 from './stripe_confirm_controller';
import c33 from './stripe_payment_controller';
import c34 from './tom_select_controller';
import c35 from './trix_default_message_controller';
import c36 from './trix_upload_controller';
import c37 from './visibility_toggle_controller';
import c38 from './welcome_message_controller';
export const definitions = [
	{identifier: 'ama-publish', controllerConstructor: c0},
	{identifier: 'amount-toggle', controllerConstructor: c1},
	{identifier: 'auto-save', controllerConstructor: c2},
	{identifier: 'autolink', controllerConstructor: c3},
	{identifier: 'beacon-link', controllerConstructor: c4},
	{identifier: 'click-tracker', controllerConstructor: c5},
	{identifier: 'confirmation', controllerConstructor: c6},
	{identifier: 'copy-to-clipboard', controllerConstructor: c7},
	{identifier: 'email-validation', controllerConstructor: c8},
	{identifier: 'expandable-content', controllerConstructor: c9},
	{identifier: 'extra-support', controllerConstructor: c10},
	{identifier: 'feed-item-switcher', controllerConstructor: c11},
	{identifier: 'form', controllerConstructor: c12},
	{identifier: 'global-promo', controllerConstructor: c13},
	{identifier: 'image-preview', controllerConstructor: c14},
	{identifier: 'likes', controllerConstructor: c15},
	{identifier: 'login-code', controllerConstructor: c16},
	{identifier: 'login', controllerConstructor: c17},
	{identifier: 'new-tab-link', controllerConstructor: c18},
	{identifier: 'password-validator', controllerConstructor: c19},
	{identifier: 'plan-toggle', controllerConstructor: c20},
	{identifier: 'plan-unwrap', controllerConstructor: c21},
	{identifier: 'play-controls', controllerConstructor: c22},
	{identifier: 'player-preference', controllerConstructor: c23},
	{identifier: 'preview', controllerConstructor: c24},
	{identifier: 'preview-target', controllerConstructor: c25},
	{identifier: 'range-selector', controllerConstructor: c26},
	{identifier: 'reorderable-list', controllerConstructor: c27},
	{identifier: 'search-filter', controllerConstructor: c28},
	{identifier: 'search-toggle', controllerConstructor: c29},
	{identifier: 'share-link', controllerConstructor: c30},
	{identifier: 'status-page', controllerConstructor: c31},
	{identifier: 'stripe-confirm', controllerConstructor: c32},
	{identifier: 'stripe-payment', controllerConstructor: c33},
	{identifier: 'tom-select', controllerConstructor: c34},
	{identifier: 'trix-default-message', controllerConstructor: c35},
	{identifier: 'trix-upload', controllerConstructor: c36},
	{identifier: 'visibility-toggle', controllerConstructor: c37},
	{identifier: 'welcome-message', controllerConstructor: c38},
];
