import { Controller } from "stimulus";

export default class AutoSaveController extends Controller {
    static targets = ["input", "inputGroup", "status"]
    static values = { disabled: Boolean }

    initialize() {
        this.changes = new Set([]);
    }

    updateStatus() {
        if (this.disabledValue) {
            this.statusTarget.innerText = "Auto-save disabled";
        } else {
            if (this.changes.size === 0) {
                this.statusTarget.innerText = this.statusTarget.dataset.savedText;
            } else {
                this.statusTarget.innerText = this.statusTarget.dataset.unsavedText;
            }
        }
    }

    statusTargetConnected() {
        this.updateStatus();
    }

    inputTargetConnected(el) {
        if (this.disabledValue) return;

        this.registerInput(el);
    }

    inputGroupTargetConnected(el) {
        if (this.disabledValue) return;

        let inputs = el.querySelectorAll('input, select');

        for (let input of inputs) {
            this.registerInput(input, el);
        }

        // Track children changes incase more inputs added/removed.
        // NB: episode timestamps use this functionality
        let observer = new MutationObserver((mutationList) => {
            for (const mutation of mutationList) {
                if (mutation.type === 'childList') {
                    mutation.addedNodes.forEach(node => {
                        let newInputs = node.querySelectorAll('input, select');
                        for (let input of newInputs) {
                            this.registerInput(input, el);
                        }
                    });

                    // If there were any fields removed we should trigger a save.
                    if (mutation.removedNodes.length > 0) {
                        this.save(inputs[0], el);
                    }
                }
            }
        });
        observer.observe(el, { childList: true, subtree: true });
    }

    // el:         input field to monitor for changes
    // inputGroup: the root el of the group to search for values
    registerInput(el, inputGroup = null) {
        let dirty = false;
        let autoSaveTimeout = null;

        const handleSave = async () => {
            if (dirty && (await this.save(el, inputGroup))) {
                this.changes.delete(inputGroup?.id || el.id);
                this.updateStatus();
                dirty = false;
            }
        };

        const handleChange = () => {
            if (autoSaveTimeout) clearTimeout(autoSaveTimeout);
            if (!dirty) {
                this.changes.add(inputGroup?.id || el.id);
                this.updateStatus();
            }
            dirty = true;
            autoSaveTimeout = setTimeout(handleSave, 10000);
        };

        el.addEventListener("trix-blur", handleSave);
        el.addEventListener("change", handleSave);
        el.addEventListener("trix-change", handleChange);
        el.addEventListener("input", handleChange);
    }

    async save(el, inputGroup = null) {
        let csrfToken = document.getElementsByName("csrf-token")[0].content;

        let formData = new FormData();
        formData.append("authenticity_token", csrfToken);

        let inputs = [el];
        if (inputGroup) {
            inputGroup.querySelectorAll("input[name], select[name]").forEach(el => inputs.push(el));
        }

        for (let input of inputs) {
            if (input.tagName === "SELECT") {
                if (input.selectedOptions.length > 0) {
                    for (let i = 0; i < input.selectedOptions.length; i++) {
                        formData.append(input.name, input.selectedOptions[i].value);
                    }
                } else {
                    // Special case so we know to delete any already selected options if
                    // all existing selections were cleared.
                    formData.append(input.name, 0);
                } 
            } else if (input.type === "file") {
                for (let file of input.files) {
                    formData.append(input.name, file);
                }
            } else if (input.type === "checkbox") {
                if (input.checked) {
                    formData.append(input.name, input.value);
                }
            } else {
                formData.append(input.name, input.value);
            }
        }

        const payload = {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
            },
            body: formData,
        };

        let ok = false;
        if (el.tagName === "TRIX-EDITOR") {
            ok = await fetch(el.inputElement.form.action, payload)
                .then(res => res.json())
                .then(data => data.ok)
                .catch(() => false);
        } else {
            ok = await fetch(el.form.action, payload)
                .then(res => res.json())
                .then(data => data.ok)
                .catch(() => false);
        }

        if (ok) {
            const event = new CustomEvent("change-saved");
            el.dispatchEvent(event);
        }

        return ok;
    }
}
