// from https://discuss.hotwire.dev/t/button-to-not-showing-the-data-confirm/2046/2

import { Controller } from "stimulus"

export default class Confirmation extends Controller {
  static values = { message: String };

  confirm() {
    if (!(window.confirm(event.target.dataset.messageValue))) {
      event.preventDefault()
    }
  }
}
