import { Controller } from 'stimulus';
import { logEvent } from '../utils/analytics';

/**
 * Logs events to analytics whenever this element is clicked. Specify the name
 * of the event in data-event. All other data properties will be sent to analytics as well.
 */
export default class ClickTracker extends Controller {
  connect() {
    this.element.addEventListener('click', () => {
      logEvent(this.element.dataset.event, {...this.element.dataset});
    });
  }
}
