import { Controller } from "stimulus";

/**
 * Reorders items by rearranging them in the DOM. Just specify [draggable] on the elements you want to be draggable.
 * If you need to persist position, just have a hidden array input, and we can rely on the order of the elements in the DOM.
 */
export default class ReorderableListController extends Controller {
  static values = {
    submitOnReorder: Boolean, // If set to true, will submit whichever form this element is inside whenever a reorder happens.
  };

  connect() {
    this.element.addEventListener("dragstart", this.dragStart.bind(this));
    this.element.addEventListener("dragover", this.dragOver.bind(this));
    this.element.addEventListener("drop", this.drop.bind(this));
    this.element.querySelectorAll("trix-editor, input").forEach((editor) => {
      editor.addEventListener("focus", this.disableDrag.bind(this));
      editor.addEventListener("blur", this.enableDrag.bind(this));
    });
  }

  disableDrag() {
    this.element.querySelectorAll("[draggable]").forEach((el) => el.draggable = false);
  }

  enableDrag() {
    this.element.querySelectorAll("[draggable]").forEach((el) => el.draggable = true);
  }

  dragStart(event) {
    const target = event.target.closest("[draggable]");
    event.dataTransfer.setData("text/plain", target.id);

    setTimeout(() => {
      target.classList.add('hidden');
    }, 0);
  }

  drop(event) {
    event.preventDefault();

    const data = event.dataTransfer.getData("text/plain");
    const target = this.currentDragTarget;
    const dragged = document.getElementById(data);
    target.insertAdjacentElement("beforebegin", dragged);

    dragged.classList.remove('hidden');

    this.removeIndicators();

    if (this.submitOnReorderValue) {
      this.element.closest("form").submit();
    }
  }

  dragOver(event) {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";

    const target = event.target.closest("[draggable]");

    if (target && target != this.currentDragTarget) {
      this.removeIndicators();
      this.addIndicator(target);
      this.currentDragTarget = target;
    }
  }

  removeIndicators() {
    this.element.querySelectorAll("[data-drag-indicator]").forEach((el) => el.remove());
  }

  addIndicator(target) {
    const indicatorDiv = document.createElement("div");
    indicatorDiv.dataset.dragIndicator = true;
    indicatorDiv.classList.add("bg-gray-200", "h-12", "w-full", "mb-2");
    target.insertAdjacentElement("beforebegin", indicatorDiv);
  }
}