import { Controller } from 'stimulus'

export default class WelcomeMessage extends Controller {
  static targets = ["advancedMessageSettings", "baseFeature", "advancedFeature"]

  initialize() {
    if ( this.advancedMessageSettingsTarget.checked ) {
      this.baseFeatureTarget.classList.remove("hidden")
      this.advancedFeatureTarget.classList.add("hidden")
    } else {
      this.baseFeatureTarget.classList.add("hidden")
      this.advancedFeatureTarget.classList.remove("hidden")
    }
  }

  toggleAction() {
    if ( this.advancedMessageSettingsTarget.checked ) {
      this.baseFeatureTarget.classList.remove("hidden")
      this.advancedFeatureTarget.classList.add("hidden")
    } else {
      this.baseFeatureTarget.classList.add("hidden")
      this.advancedFeatureTarget.classList.remove("hidden")
    }
  }
}
