import { Controller } from 'stimulus';

export default class GlobalPormo extends Controller {
  static targets = ["bool", "plans", "planCheckbox"]

  initialize() {
    const isGlobal = this.boolTarget.value === "true"

    if (isGlobal) {
      this.plansTarget.classList.add("hidden");
      this.planCheckboxTargets.forEach((target) => { target.checked = false })
    } else {
      this.plansTarget.classList.remove("hidden");
    }
  }

  toggle() {
    const isGlobal = event.target.value === "true"

    if (isGlobal) {
      this.plansTarget.classList.add("hidden");
      this.planCheckboxTargets.forEach((target) => { target.checked = false })
    } else {
      this.plansTarget.classList.remove("hidden");
    }
  }
}
