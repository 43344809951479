import { Controller } from "stimulus";

const ALLOWED_TYPES = [
    "audio/mp3",
    "audio/mpeg",
    "audio/wav",
    "video/mp4",
    "video/quicktime"
];

const AUDIO_TYPES = [
    "audio/mp3",
    "audio/mpeg",
    "audio/wav",
];

const VIDEO_TYPES = [
    "video/mp4",
    "video/quicktime",
];

export default class UploadController extends Controller {
    connect() {
        this.episodeId = this.episodeIdValue;
        this.uppy = new window.Uppy.Uppy(this.uppyConfig());
        this.attachTus(this.uppy);

        this.encodingWorker = new Worker(this.workerValue);
        this.encodingWorker.onmessage = (event) => {
            const blob = new Blob(event.data, {type: 'audio/mp3'});
            const audioFile = new File([blob], 'audio-extracted.mp3', { type: 'audio/mp3' });
            this.extracting = false;
            this.toggleExtractionNotice();
            this.uppy.addFile(audioFile);
        };
    }

    disconnect() {
        this.encodingWorker.terminate();
    }

    dropZoneTargetConnected(el) {
        const prevent = (e) => {
            e.preventDefault();
            e.stopPropagation();
        };

        let icon = document.getElementById("cloud");

        el.addEventListener("dragover", prevent);
        el.addEventListener("dragenter", (e) => {
            prevent(e);
            icon.classList.add("text-blue-700");
            icon.classList.remove("text-gray-500");
        });
        el.addEventListener("dragleave", (e) => {
            prevent(e);
            icon.classList.add("text-gray-500");
            icon.classList.remove("text-blue-700");
        });

        el.addEventListener("drop", (e) => {
            e.preventDefault();

            const file = e.dataTransfer.files[0];

            let replace;
            if (VIDEO_TYPES.includes(file.type)) {
                replace = this.getFileByType(VIDEO_TYPES);
            } else {
                replace = this.getFileByType(AUDIO_TYPES);
            }
            if (replace) this.uppy.removeFile(replace.id);
            this.uppy.addFile(file);
        });
    }

    eventTargetConnected(el) {
        el.addEventListener("upload-started", (e) => {
            const { episodeId } = e.detail;
            if (episodeId === null) return;

            window.Turbo.visit(`/dashboard/channels/episodes/${episodeId}/detail`, { frame: "step" });
        });
    }

    previewVideoTargetConnected(el) {
        if (this.hasVideoImageInputTarget) {
            this.videoImageInputTarget.addEventListener("change", () => {
                if (FileReader && this.videoImageInputTarget.files && this.videoImageInputTarget.files.length) {
                    var fr = new FileReader();
                    fr.onload = function () {
                        el.poster = fr.result;
                        el.load(); // Reset the video so the new thumbnail displays
                    }
                    fr.readAsDataURL(this.videoImageInputTarget.files[0]);
                }
            });
        }
        
        const video = this.getFileByType(VIDEO_TYPES);
        if (!video || !this.hasPreviewVideoTarget) return;

        this.updateVideoPreview(video); 
    }

    previewAudioTargetConnected(el) {
        if (this.hasAudioImageInputTarget) {
            this.audioImageInputTarget.addEventListener("change", () => {
                if (FileReader && this.audioImageInputTarget.files && this.audioImageInputTarget.files.length) {
                    var fr = new FileReader();
                    fr.onload = function () {
                        document.getElementById("preview-image").src = fr.result;
                    }
                    fr.readAsDataURL(this.audioImageInputTarget.files[0]);
                }
            });
        }

        const audio = this.getFileByType(["audio/mp3", "audio/mpeg", "audio/wav"]);
        if (!audio || !this.hasPreviewAudioTarget) return;

        this.updateAudioPreview(audio);
    }

    extractionNoticeTargetConnected(el) {
        this.toggleExtractionNotice();
    }

    videoOnlyTargetConnected(el) {
        const video = this.getFileByType(VIDEO_TYPES);
        if (!video) return;
        
        el.classList.remove("hidden");
    }

    toggleExtractionNotice() {
        if (this.hasExtractionNoticeTarget) {
            if (this.extracting) {
                this.extractionNoticeTarget.classList.remove('hidden');
                this.previewAudioTarget.parentElement.classList.add('hidden');
            } else {
                this.extractionNoticeTarget.classList.add('hidden');
            }
        }
    }

    updateAudioPreview(audio) {
        if (this.hasPreviewAudioTarget) {
            const audioEl = this.previewAudioTarget.querySelector("audio");
            audioEl.src = URL.createObjectURL(audio.data);
            this.previewAudioTarget.parentElement.classList.remove("hidden");
        }
    }

    updateVideoPreview(video) {
        if (this.hasPreviewVideoTarget) {
            const current = this.previewVideoTarget.querySelector("source");
            const source = document.createElement("source");
            source.src = URL.createObjectURL(video.data);
            source.type = video.type;
            if (current) {
                this.previewVideoTarget.removeChild(current);
            }
            this.previewVideoTarget.appendChild(source);
            this.previewVideoTarget.load(); // Force a reload after changing
            this.previewVideoTarget.parentElement.classList.remove("hidden");
        }
    }

    get episodeId() {
        return this._episodeId || null;
    }

    set episodeId(id) {
        this._episodeId = id;
    }

    getFileByType(fileTypes) {
        const files = this.uppy.getFiles();
        for (const file of files) {
            if (fileTypes.includes(file.type)) return file;
        }
        return null;
    }

    uppyConfig() {
        return {
            autoProceed: true,
            onBeforeFileAdded: this.onBeforeFileAdded.bind(this),
        };
    }

    addFile(e) {
        const file = e.target.files[0];
        let replace;
        if (VIDEO_TYPES.includes(file.type)) {
            replace = this.getFileByType(VIDEO_TYPES);
        } else {
            replace = this.getFileByType(AUDIO_TYPES);
        }
        if (replace) this.uppy.removeFile(replace.id);
        this.uppy.addFile(file);
    }

    exit(e) {
        if (this.uploading || this.extracting) {
            if (!window.confirm("Exiting now will cancel in progress uploads.\nAre you sure you want to close?")) {
                e.stopImmediatePropagation();
                return;
            }
        }

        window.Turbo.visit(`/dashboard/channels/episodes/${this.episodeId}`);
    }

    async removeAudio(e) {
        const audio = this.getFileByType(["audio/mp3", "audio/mpeg", "audio/wav"]);
        if (audio) {
            this.uppy.removeFile(audio.id);
        }

        if (!this.uploading) {
            const csrfToken = document.getElementsByName("csrf-token")[0].content;

            let formData = new FormData();
            formData.append("authenticity_token", csrfToken);

            const payload = {
                method: "DELETE",
                headers: {
                    "Accept": "application/json",
                },
                body: formData
            };

            await fetch(`/dashboard/channels/episodes/${this.episodeId}/remove_audio`, payload);
        }

        this.previewAudioTarget.parentElement.classList.add("hidden");
    }

    onBeforeFileAdded(file, files) {
        if (!ALLOWED_TYPES.includes(file.type)) {
            this.errorTarget.innerText = "Unsupported file type provided: " + file.type;
            return false
        }

        // Pull media duration for metadata.
        var objectURL = URL.createObjectURL(file.data);
        var audio = document.createElement('audio');
        audio.src = objectURL;
        audio.onloadedmetadata = () => {
            file.meta.duration = audio.duration
            URL.revokeObjectURL(objectURL)
        }

        return true;
    }

    extractAudio(file) {
        this.extracting = true;
        this.toggleExtractionNotice();

        // Extract and encode audio to mp3.
        const audioContext = new (window.AudioContext || window.webkitAudioContext)();
        let reader = new FileReader();

        const interleave = (inputL, inputR) => {
            let length = inputL.length + inputR.length
            let result = new Float32Array(length)

            let index = 0
            let inputIndex = 0

            while (index < length) {
                result[index++] = inputL[inputIndex]
                result[index++] = inputR[inputIndex]
                inputIndex++
            }
            return result
        }

        reader.onload = () => {
            // FYI: AudioContext only exists on the main thread so we have
            // to do this part before passing off to the web worker.
            audioContext.decodeAudioData(reader.result).then(audioData => {
                const offlineAudioContext = new OfflineAudioContext(1, audioData.duration * 44100, 44100);
                const soundSource = offlineAudioContext.createBufferSource();
                soundSource.buffer = audioData;
                soundSource.connect(offlineAudioContext.destination);
                soundSource.start();

                offlineAudioContext.startRendering().then(buf => {
                    const data = {
                        numChannels: buf.numberOfChannels,
                        sampleRate: buf.sampleRate,
                        samples: buf.numberOfChannels === 2 ? interleave(buf.getChannelData(0), buf.getChannelData(1)) : buf.getChannelData(0),
                    };
                    this.encodingWorker.postMessage(data, [data.samples.buffer]);
                });
            });
        };
        reader.readAsArrayBuffer(file.data);
    }

    async fileAdded(file) {
        if (this.hasWorkingTarget) {
            this.workingTarget.classList.remove("hidden");
        }

        const mediaType = file.type.split("/")[0];

        let url = `/dashboard/channels/episodes/presigned_upload?type=${mediaType}&title=${file.name}&episode=${this.episodeId}`;
        if (this.hasDynamicValue && this.hasTriggerEventValue) {
            url += `&dynamic=${this.dynamicValue}&trigger_event=${this.triggerEventValue}`;
        }

        await fetch(url)
            .then(response => response.json())
            .then(data => {
                const { episode_id, ...bunny } = data;
                this.episodeId = episode_id;
                file.meta.bunny = bunny;

                if (this.hasEventTarget) {
                    const event = new CustomEvent("upload-started", { detail: { episodeId: this.episodeId } });
                    this.eventTarget.dispatchEvent(event);
                }

                if (VIDEO_TYPES.includes(file.type)) {
                    this.updateVideoPreview(file)
                    // DISABLED: this.extractAudio(file);
                } else {
                    this.updateAudioPreview(file);
                }
            })
            .finally(() => {
                if (this.hasWorkingTarget) {
                    this.workingTarget.classList.remove("hidden");
                }
            });
    }

    progress(percentage) {
        if (this.hasShowUploadingTarget) {
            if (this.extracting || percentage < 100) {
                this.showUploadingTargets.forEach(t => t.classList.remove("hidden"));
            } else {
                this.showUploadingTargets.forEach(t => t.classList.add("hidden"));
            }
        }
        if (this.hasShowUploadedTarget) {
            if (!this.extracting && percentage == 100) {
                this.showUploadedTargets.forEach(t => t.classList.remove("hidden"));
            } else {
                this.showUploadedTargets.forEach(t => t.classList.add("hidden"));
            }
        }
        if (this.hasUploadPercentageTarget) {
            if (this.extracting) {
                this.uploadPercentageTargets.forEach(t => t.innerText = `Extracting audio...`);
            } else {
                this.uploadPercentageTargets.forEach(t => t.innerText = `Uploading... ${percentage}%`);
            }
        }
    }

    individualProgress(file, progress) {
        const percentage = Math.round(progress.bytesUploaded / progress.bytesTotal * 100);

        if (VIDEO_TYPES.includes(file.type)) {
            if (this.hasShowVideoUploadingTarget) {
                if (percentage < 100) {
                    this.showVideoUploadingTargets.forEach(t => t.classList.remove("hidden"));
                } else {
                    this.showVideoUploadingTargets.forEach(t => t.classList.add("hidden"));
                }
            }
            if (this.hasShowVideoUploadedTarget) {
                if (percentage == 100) {
                    this.showVideoUploadedTargets.forEach(t => t.classList.remove("hidden"));
                } else {
                    this.showVideoUploadedTargets.forEach(t => t.classList.add("hidden"));
                }
            }
            if (this.hasVideoUploadPercentageTarget) {
                this.videoUploadPercentageTargets.forEach(t => t.innerText = `Uploading... ${percentage}%`);
            }
        } else {
            if (this.hasShowAudioUploadingTarget) {
                if (percentage < 100) {
                    this.showAudioUploadingTargets.forEach(t => t.classList.remove("hidden"));
                } else {
                    this.showAudioUploadingTargets.forEach(t => t.classList.add("hidden"));
                }
            }
            if (this.hasShowAudioUploadedTarget) {
                if (percentage == 100) {
                    this.showAudioUploadedTargets.forEach(t => t.classList.remove("hidden"));
                } else {
                    this.showAudioUploadedTargets.forEach(t => t.classList.add("hidden"));
                }
            }
            if (this.hasAudioUploadPercentageTarget) {
                this.audioUploadPercentageTargets.forEach(t => t.innerText = `Uploading... ${percentage}%`);
            }
        }
    }

    async commitUpload(file, response) {
        let csrfToken = document.getElementsByName("csrf-token")[0].content;

        let formData = new FormData();
        formData.append("authenticity_token", csrfToken);

        const metadata = {
            size: file.size,
            filename: file.name,
            mime_type: file.type,
            duration: file.meta.duration,
        };
        if (VIDEO_TYPES.includes(file.type)) {
            formData.append("episode[podcast_video]", file.meta.bunny.video_id);
            formData.append("episode[podcast_video_meta]", JSON.stringify(metadata));
        } else {
            formData.append("episode[podcast_audio]", file.meta.bunny.video_id);
            formData.append("episode[podcast_audio_meta]", JSON.stringify(metadata));
        }

        const payload = {
            method: "PATCH",
            headers: {
                "Accept": "application/json",
            },
            body: formData,
        };

        await fetch(`/dashboard/channels/episodes/${this.episodeId}`, payload);
    }

    trackUploadStart() {
        this.uploading = true;
    }

    trackUploadFinish() {
        this.uploading = false;
    }

    retryUpload(file, error, response) {
        // Automatically retry uploads on failure. Upload failures are usually intermittent.
        this.uppy.retryUpload(file.id);
    }

    attachTus(uppy) {
        uppy.use(window.Uppy.Tus, {
            endpoint: "https://video.bunnycdn.com/tusupload",
            async onBeforeRequest(req, file) {
                var data = file.meta.bunny

                req.setHeader('AuthorizationSignature', data.signature);
                req.setHeader('AuthorizationExpire', data.expire);
                req.setHeader('VideoId', data.video_id);
                req.setHeader('LibraryId', data.library_id);
            },
        });

        uppy.on('file-added', this.fileAdded.bind(this))
            .on('progress', this.progress.bind(this))
            .on('upload-progress', this.individualProgress.bind(this))
            .on('upload', this.trackUploadStart.bind(this))
            .on('upload-success', this.commitUpload.bind(this))
            .on('upload-error', this.retryUpload.bind(this))
            .on('complete', this.trackUploadFinish.bind(this));
    }
}

UploadController.targets = [
    "event", "dropZone", "working", "error", "previewVideo", "previewAudio",
    "videoImageInput", "audioImageInput",
    "showUploading", "showUploaded", "uploadPercentage",
    "showAudioUploading", "showAudioUploaded", "audioUploadPercentage",
    "showVideoUploading", "showVideoUploaded", "videoUploadPercentage",
    "extractionNotice", "videoOnly"
];
UploadController.values = { episodeId: Number, worker: String, dynamic: Boolean, triggerEvent: String };
