import { Controller } from 'stimulus';

export default class ShowController extends Controller {
  addItem(event) {
    const id = event.currentTarget.value;
    const elementToMove = this.disabledPlaylistsTarget.querySelector(`#module-${id.replace(/:/g, '\\:')}`);
    this.activePlaylistsTarget.appendChild(elementToMove);
    event.currentTarget.remove(event.currentTarget.selectedIndex);
    event.currentTarget.selectedIndex = 0;
    event.currentTarget.blur();
  }

  removeItem(event) {
    const elementToMove = event.currentTarget.closest("[draggable]");
    this.disabledPlaylistsTarget.appendChild(elementToMove);
    this.dropdownTarget.add(new Option(elementToMove.dataset.name, elementToMove.id.replace("module-", ""), false, false));
  }
}

ShowController.targets = ["activePlaylists", "disabledPlaylists", "dropdown"];