import { Controller } from "stimulus";

const STATUS_PAGE_URL = "https://s7m8lv69tv03.statuspage.io/api/v2/summary.json";

export default class StatusPageController extends Controller {
  connect() {
    this.loadStatus();
  }

  async loadStatus() {
    const response = await fetch(STATUS_PAGE_URL);
    const data = await response.json();
    this.indicatorTargets.forEach((el) => el.classList.remove("hidden"));
    if (data.status.indicator == "minor" || data.status.indicator == "major") {
      this.indicatorTargets.forEach((el) => el.classList.add("bg-orange-500"));
      this.textTarget.textContent = data.status.description;
    } else if (data.status.indicator == "critical") {
      this.indicatorTargets.forEach((el) => el.classList.add("bg-red-500"));
      this.textTarget.textContent = data.status.description;
    }
  }

}

StatusPageController.targets = ["indicator", "text"];