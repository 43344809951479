import c0 from './add_button_controller';
import c1 from './button_controller';
import c2 from './connect_link_controller';
import c3 from './media_progress_controller';
import c4 from './notification_controller';
import c5 from './referral_source_controller';
import c6 from './shareable_link_controller';
import c7 from './thumbnail_controller';
export const definitions = [
	{identifier: 'add-button', controllerConstructor: c0},
	{identifier: 'button', controllerConstructor: c1},
	{identifier: 'connect-link', controllerConstructor: c2},
	{identifier: 'media-progress', controllerConstructor: c3},
	{identifier: 'notification', controllerConstructor: c4},
	{identifier: 'referral-source', controllerConstructor: c5},
	{identifier: 'shareable-link', controllerConstructor: c6},
	{identifier: 'thumbnail', controllerConstructor: c7},
];
