import { Controller } from 'stimulus';
import { debounce } from 'debounce';

/* Provides functionality for an inline form preview. The preview is intended to be an iframe of the content
   being edited that has a PreviewTargetController attached to it. As elements on the form change (you'll need to
   hook these up to hit the 'reload' action on this controller), the preview will be reloaded with the updated
   data. */
   
export default class PreviewController extends Controller {
  static targets = ["previewFrame", "previewTurboFrame", "form"]

  connect() {
    this.reload = debounce(this.reload, 200);
  }

  reload() {
    if (this.hasPreviewTurboFrameTarget) {
      const originalTarget = this.formTarget.action;
      const originalMethod = this.formTarget.method;
      this.formTarget.dataset.turboFrame = this.previewTurboFrameTarget.id;
      this.formTarget.action = window.location.href;
      this.formTarget.method = 'GET';
      this.formTarget.requestSubmit();
      window.requestAnimationFrame(() => {
        this.formTarget.dataset.turboFrame = null;
        this.formTarget.action = originalTarget;
        this.formTarget.method = originalMethod;
      });
    } else {
      this.previewFrameTarget.contentWindow.postMessage({
        name: "supercast-preview-update",
        previewData: Object.fromEntries(new FormData(this.formTarget).entries())
      });
    }
  }

  changeSrc(event) {
    this.previewFrameTarget.src = event.target.dataset.previewSrcParam;
  }
}
