import { Controller } from "stimulus";

export default class extends Controller {
    static targets = ["question", "submit"];
    static values = { questionIds: Array }

    connect() {
        this.questions = this.questionIdsValue
        this.handleSubmitButton();
    }

    addOrRemoveQuestion(event) {
        const id = parseInt(event.target.dataset.id);
        if (this.questions.includes(id)) {
            this.questions = this.questions.filter(question => question !== id)
        } else {
            this.questions.push(id)
        }
        this.handleSubmitButton()
    }

    handleSubmitButton() {
        if (this.questions.length > 0) {
            this.submitTarget.classList.remove("hidden")
        } else {
            this.submitTarget.classList.add("hidden")
        }
    }
}
