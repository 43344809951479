import { Controller } from 'stimulus';

// Ensures all links for a particular DOM element open in a new tab. Can be
// used with rich text fields to keep people in the UI.
export default class NewTabLinkController extends Controller {
  connect() {
    this.element.querySelectorAll("a").forEach(linkEl => {
      linkEl.target = "_blank";
    });
  }
}
