import { Controller } from "stimulus";

export default class StatsComponentController extends Controller {
    static targets = ["link"]

    linkTargetConnected(el) {
        const frameId = el.dataset.turboFrame;
        if (!frameId) return;

        const frame = document.getElementById(frameId);
        frame.addEventListener("turbo:before-frame-render", () => {
            if (frame.src.startsWith(el.href)) {
                el.classList.add("border-b-purple-500");
                el.classList.remove("border-b-transparent");
                el.classList.remove("hover:border-b-gray-200");
            } else {
                el.classList.add("border-b-transparent");
                el.classList.add("hover:border-b-gray-200");
                el.classList.remove("border-b-purple-500");
            }
        });
    }
}

