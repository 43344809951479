import { Controller } from 'stimulus';

export default class SearchFilterController extends Controller {
  static targets = [ "dropdown", "trigger" ]

  connect() {
    this.initialTriggerText = this.triggerTarget.textContent;
    this.textPrefix = this.element.dataset.textPrefix || '';
    this.selectedClass = this.element.dataset.selectedClass || 'bg-purple-600 text-white';
    this.deselectedClass = this.element.dataset.deselectedClass || 'bg-white text-black';
    this.setTriggerText();
    this.addSubmitButton();
  }

  /**
   * Adds a hidden submit button to the form so that we can trigger JS
   * event handlers on the form (tried requestSubmit and dispatchEvent, but they didn't work)
   */
  addSubmitButton() {
    this.hiddenSubmit = document.createElement('input');
    this.hiddenSubmit.type = 'submit';
    this.hiddenSubmit.name = 'submit';
    this.hiddenSubmit.hidden = true;
    this.element.appendChild(this.hiddenSubmit);
  }

  search(event) {
    event.preventDefault();
    const form = event.target.closest('form');
    if (form.requestSubmit) {
      form.requestSubmit();
    } else {
      this.hiddenSubmit.click();
    }
    this.setTriggerText();
  }

  show() {
    this.dropdownTarget.hidden = false;
  }

  hide() {
    this.dropdownTarget.hidden = true;
}

  selectNone(event) {
    event.preventDefault();
    this.dropdownTarget.querySelectorAll('input:checked').forEach(node => node.checked = false);
    this.search(event);
    this.hide();
  }

  setTriggerText() {
    const labels = [
      ...this.dropdownTarget.querySelectorAll('input:checked')
    ].map(node => 
      document.querySelector(`label[for="${node.id}"]`).textContent
    );

    if (labels.length == 0) {
      this.triggerTarget.textContent = this.initialTriggerText;
      this.selectedClass.split(" ").forEach(c => this.triggerTarget.classList.remove(c));
      this.deselectedClass.split(" ").forEach(c => this.triggerTarget.classList.add(c));
    } else if (labels.length == 1) {
      this.triggerTarget.textContent = [this.textPrefix, labels[0]].join(" ");
      this.selectedClass.split(" ").forEach(c => this.triggerTarget.classList.add(c));
      this.deselectedClass.split(" ").forEach(c => this.triggerTarget.classList.remove(c));
    } else {
      this.triggerTarget.textContent = [this.textPrefix, `${labels.length} selected`].join(" ");
      this.selectedClass.split(" ").forEach(c => this.triggerTarget.classList.add(c));
      this.deselectedClass.split(" ").forEach(c => this.triggerTarget.classList.remove(c));
    }
  }
}
