import c0 from './accordion_component_controller';
import c1 from './alert_bar_controller';
import c2 from './audio_player_component_controller';
import c3 from './button_component_controller';
import c4 from './dropdown_controller';
import c5 from './feature_callout_component_controller';
import c6 from './forms/timestamp_component_controller';
import c7 from './media/audio_player_controller';
import c8 from './media/video_player_controller';
import c9 from './modal_component_controller';
import c10 from './offer_component_controller';
import c11 from './patreon_offer_component_controller';
import c12 from './price_component_controller';
import c13 from './qr_code_controller';
import c14 from './stats_component_controller';
import c15 from './targeting_component_controller';
import c16 from './tus_upload_component_controller';
import c17 from './typeahead_component_controller';
export const definitions = [
	{identifier: 'accordion-component', controllerConstructor: c0},
	{identifier: 'alert-bar', controllerConstructor: c1},
	{identifier: 'audio-player-component', controllerConstructor: c2},
	{identifier: 'button-component', controllerConstructor: c3},
	{identifier: 'dropdown', controllerConstructor: c4},
	{identifier: 'feature-callout-component', controllerConstructor: c5},
	{identifier: 'forms--timestamp-component', controllerConstructor: c6},
	{identifier: 'media--audio-player', controllerConstructor: c7},
	{identifier: 'media--video-player', controllerConstructor: c8},
	{identifier: 'modal-component', controllerConstructor: c9},
	{identifier: 'offer-component', controllerConstructor: c10},
	{identifier: 'patreon-offer-component', controllerConstructor: c11},
	{identifier: 'price-component', controllerConstructor: c12},
	{identifier: 'qr-code', controllerConstructor: c13},
	{identifier: 'stats-component', controllerConstructor: c14},
	{identifier: 'targeting-component', controllerConstructor: c15},
	{identifier: 'tus-upload-component', controllerConstructor: c16},
	{identifier: 'typeahead-component', controllerConstructor: c17},
];
