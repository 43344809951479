import { Controller } from 'stimulus';

export default class TrixUploadController extends Controller {
  connect() {
    this.editor = this.element.querySelector('trix-editor').editor;
    this.attachTypes = this.element.dataset.attachTypes || ['image', 'file'];

    this.addToolbarButtons();
  }

  /* Adds toolbar buttons to trix editor - one for image upload and one for file attachment */
  addToolbarButtons() {
    const toolbarElement = this.element.querySelector('trix-toolbar');
    toolbarElement.querySelector(".trix-button-group--file-tools").innerHTML = [
      this.attachTypes.includes('file') && `<button type="button" data-attach="file" class="trix-button" style="padding-top: 0; padding-bottom: 0" title="Attach a file" tabindex="-1">${this.attachmentIcon()}</button>`,
      this.attachTypes.includes('image') && `<button type="button" data-attach="image" class="trix-button" style="padding-top: 0; padding-bottom: 0" title="Insert an image" tabindex="-1">${this.imageIcon()}</button>`
    ].filter(n => n).join('');

    toolbarElement.querySelectorAll("[data-attach]").forEach(el => {
      el.addEventListener('click', (event) => {
        const type = event.currentTarget.dataset.attach;
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.setAttribute('accept', type === 'image' ? 'image/*' : '*');
        input.click();

        input.addEventListener('change', () => {
          this.uploadFile(input, type);
        });
      });
    });
  }

  async uploadFile(input, type) {
    const file = input.files[0];
    if (file.name.length > 50) {
        alert('File name is too long. Maximum length is 50, rename the file and try again.');
        return;
    }
    const response = await this.uploadAttachment(file, type);

    if (response.href) {
      let html
      if (type == 'file') {
        html = `<a href="${response.href}" target="_blank">${file.name}</a>`;
      } else if (type == 'image') {
        html = `<img src="${response.href}" alt="${file.name}">`;
      }
      this.editor.insertHTML(html);
    }
  }

  /* Uploads attachment to /dashboard/shared/attachments and returns the JSON response */
  async uploadAttachment(file, type) {
    const form = new FormData();
    form.append('Content-Type', file.type);
    form.append('attachment[file]', file);
    form.append('attachment[type]', type)

    const response = await fetch('/dashboard/shared/attachments', {
      method: 'POST',
      body: form,
      headers: {
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        'Accept': 'application/json'
      },
      credentials: 'same-origin'
    })

    return response.json();
  }

  attachmentIcon() {
    return `
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"><defs></defs><circle cx="12" cy="11.919" r="10" fill="#6c5be742"></circle><path d="M7.618,15.264,16.284,6.6a2.039,2.039,0,1,1,2.883,2.883L7.461,21.224a4.078,4.078,0,1,1-5.767-5.767L13.928,3.224a5.606,5.606,0,0,1,7.929,7.928l-8.665,8.666" fill="none" stroke="#00303e" stroke-linecap="round" stroke-linejoin="round"></path></svg>
      `;
  }

  imageIcon() {
    return `
     <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" height="20" width="20"><defs></defs><path d="M21.207,4.5a1,1,0,0,1,.293.707V22.5a1,1,0,0,1-1,1H3.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H16.793A1,1,0,0,1,17.5.793Z" fill="#6c5be733"></path><path d="M21.207,4.5,17.5.793A1,1,0,0,0,16.793.5H3.5a1,1,0,0,0-1,1v21a1,1,0,0,0,.293.707L21.324,4.676A.945.945,0,0,0,21.207,4.5Z" fill="#ffffff"></path><path d="M21.207,4.5a1,1,0,0,1,.293.707V22.5a1,1,0,0,1-1,1H3.5a1,1,0,0,1-1-1V1.5a1,1,0,0,1,1-1H16.793A1,1,0,0,1,17.5.793Z" fill="none" stroke="#00303e" stroke-linecap="round" stroke-linejoin="round"></path><circle cx="8.5" cy="9.5" r="2" fill="#6c5be7"></circle><path d="M13.924,12.178a.5.5,0,0,0-.848,0L10.437,16.4,9.362,14.678a.5.5,0,0,0-.848,0L5.5,19.5h13Z" fill="#6c5be7"></path><circle cx="8.5" cy="9.5" r="2" fill="none" stroke="#00303e" stroke-linecap="round" stroke-linejoin="round"></circle><path d="M13.924,12.178a.5.5,0,0,0-.848,0L10.437,16.4,9.362,14.678a.5.5,0,0,0-.848,0L5.5,19.5h13Z" fill="none" stroke="#00303e" stroke-linecap="round" stroke-linejoin="round"></path></svg>
    `;
  }
}
