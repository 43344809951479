import { Controller } from 'stimulus';

export default class RangeSelector extends Controller {
  static targets = ["rangeSelect", "customPeriodRange"]

  connect() {
    if (!this.hasRangeSelectTarget) return;

    let val = this.rangeSelectTarget.value;
    if (val == "custom") {
      this.hideElements(this.customPeriodRangeTargets)
    } else {
      this.showElements(this.customPeriodRangeTargets)
    }
  }

  hideElements(targets) {
    targets.forEach(el => el.classList.remove("hidden"));
  }

  showElements(targets) {
    targets.forEach(el => el.classList.add("hidden"));
  }
}
