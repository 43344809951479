import { Controller } from "stimulus";

export default class ConnectLink extends Controller {
  connect() {
    setTimeout(() => window.open(this.element.dataset.deepLink, "_self"), 1);
    this.element.querySelectorAll(".player-link").forEach(el => el.addEventListener("click", this.reset.bind(this)));
    this.setStatus(this.element.dataset.defaultStatus || "loading");
  }

  disconnect() {
    window.clearInterval(this.pollingInterval);
    window.clearTimeout(this.timeoutInterval);
  }

  reset() {
    this.setStatus("loading");
  }

  async checkStatus() {
    const response = await fetch(this.element.dataset.statusUrl);
    const data = await response.json();
    if (data.status == "connected") {
      this.setStatus("done");
    }
  }

  timeout() {
    this.setStatus("failed");
  }

  setStatus(status) {
    if (this.pollingInterval) window.clearInterval(this.pollingInterval);
    if (this.timeoutInterval) window.clearTimeout(this.timeoutInterval);

    if (status === "loading") {
        this.pollingInterval = window.setInterval(this.checkStatus.bind(this), 1000);
        this.timeoutInterval = window.setTimeout(this.timeout.bind(this), 20000);
    } else if (status === "failed") {
        this.pollingInterval = window.setInterval(this.checkStatus.bind(this), 5000);
    }

    this.element.querySelectorAll('[data-visible]').forEach(el => {
        if (el.dataset.visible.includes(status)) {
            el.classList.remove("hidden");
        } else {
            el.classList.add("hidden");
        }
    });
  }
}
