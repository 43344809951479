import { Controller } from 'stimulus';

export default class PlanToggle extends Controller {
  static targets = [ "toggleAction", "month", "year" ]

  initialize() {
    if ( this.data.get("enabled") === "true" ) {
      if ( this.toggleActionTarget.checked ) {
        this.hidePlans( this.monthTargets );
      } else {
        this.hidePlans( this.yearTargets );
      }
    }
  }

  // When the checkbox is checked, then we should be showing yearly plans
  toggleAction() {
    let value = event.target.checked;
    if (value) {
      this.showPlans( this.yearTargets );
      this.hidePlans( this.monthTargets );
    } else {
      this.showPlans( this.monthTargets );
      this.hidePlans( this.yearTargets );
    }
  }

  showPlans(targets) {
    targets.forEach((el) => {
      el.classList.remove("hidden");
    });
  }

  hidePlans(targets) {
    targets.forEach((el) => {
      el.classList.add("hidden");
    });
  }
}
