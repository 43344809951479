import { Controller } from 'stimulus';

export default class FeedItemSwitcher extends Controller {
  static targets = ["none", "image", "video"]

  connect() {
    if (this.videoTarget.querySelector("input").value) {
      this.video();
    } else if (this.imageTarget.querySelector("[data-url]:not([data-url='']")) {
      this.image();
    }
  }

  none() {
    this.hideAll();
    this.noneTarget.classList.remove('hidden');
  }

  image() {
    this.hideAll();
    this.imageTarget.classList.remove('hidden');
  }

  video() {
    this.hideAll();
    this.videoTarget.classList.remove('hidden');
  }

  hideAll() {
    this.imageTarget.classList.add('hidden');
    this.videoTarget.classList.add('hidden');
    this.noneTarget.classList.add('hidden');
  }
}
